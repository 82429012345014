<template>
  <WsMain>
    <WsCrud
      :modelName="$store.state.stone_model[modelName].modelName"
      :label="$store.state.stone_model[modelName].label"
      :fields="$store.state.stone_model[modelName].fields"
      :liveSearching="true"
      :showFields="showFields"
      :infiniteScroll="true"
      :labelInLocale="true"
      :creatable="false"
      :updatable="false"
      :deletable="false"
      :showExpand="false"
      :expandable="false"
      :inRowBtnRead="true"
      :pageMode="true"
    ></WsCrud>
  </WsMain>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$store.state.stone_model[this.modelName].label,
    };
  },

  data: () => ({
    modelName: "user_service_plan",
    showFields: [
      "user",
      "user.email",
      "user.tel",
      "service_plan",
      "created_at",
    ],
  }),
};
</script>